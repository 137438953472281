import NewSitesReport, { INewSitesReportReportProps } from './new-sites-report';
import { ReactWrapper } from '$pages/common/react-wrapper';

interface INewSitesReportArguments {
  scheduledReportId?: string;
}

export class NewSitesReportPage extends ReactWrapper<
  INewSitesReportReportProps,
  INewSitesReportArguments
> {
  constructor() {
    super(NewSitesReport, (params) => ({
      scheduledReportId: params.scheduledReportId,
    }));
  }
}
