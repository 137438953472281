import Button from '$components/buttons/button.react';
import CardHeader from '$components/cards/card-header/card-header.react';
import Card from '$components/cards/card/card.react';
import { styled, Tab, Tabs } from '@mui/material';
import GridCell from '$components/grid/react-grid/cell/grid-cell';
import Grid, { IReactGridColumn } from '$components/grid/react-grid/grid.react';
import { useGridData } from '$components/grid/react-grid/gridhelper';
import { downloadFileFromUrl } from '$lib/hooks/blob-utillities';
import { formatDate } from '$lib/dateHelpers';
import { NewSitesReportQuery_reports_newSites_data_assignedControllers, NewSitesReportQuery_reports_newSites_data_newSites, NewSitesReportQuery_reports_newSites_data_parkedSites, NewSitesReportQuery_reports_newSites_data_unassignedControllers } from '$typings/graphql';
import { NewSitesReportDownloadDocument, NewSitesReportDownloadQuery, NewSitesReportDownloadQueryVariables, NewSitesReportQueryDocument, NewSitesReportQueryQueryVariables, ScheduledReportFilter, SortDirection } from '$typings/graphql-codegen';
import { useCaseInsensitiveTranslation } from "$lib/hooks/case-insensitive-translation";
import React, { FC, useState } from 'react';
import { runSingleQuery } from '$pages/common/GraphQLFetcher';
import { makeSortedData } from '../../../makeSortedData';

type NewSitesSortValues = {
    sortColumnKey: "alias" | "buid" | "accessTag" | "created",
    sortDirection: SortDirection;
}

type AssignedControllersSortValues = {
  sortColumnKey: "alias" | "controllerType" | "serial" | "alternateSerial",
  sortDirection: SortDirection;
}

type UnassignedControllersSortValues = {
  sortColumnKey: "alias" | "controllerType" | "serial" | "alternateSerial",
  sortDirection: SortDirection;
}

type ParkedSitesSortValues = {
  sortColumnKey: "alias" | "buid" | "accessTag" | "created",
  sortDirection: SortDirection;
}

enum NewSitesReportGridType {
  NewSites = 'new-sites',
  AssignedControllers = 'assigned-controllers',
  UnassignedControllers = 'unassigned-controllers',
  ParkedSites = 'parked-sites'
}

const StyledTab = styled(Tab)({
  minHeight: 'auto',
  padding: '1rem',
  fontSize: '1rem'
});

const newSitesColumns: IReactGridColumn<
  NewSitesReportQuery_reports_newSites_data_newSites
>[] = [
  {
    columnTitle: 'UI_Reports_NewSites_Table_Header_SiteAlias',
    render: (row, width) => (
      <GridCell
        width={width}
        className="link clickable"
        columnLink={`/sitedetails/${row.siteId}`}
      >
        {row.alias?.trim()}
      </GridCell>
    ),
    columnKey: 'alias',
    initialWidth: 300
  },
  {
    columnTitle: 'UI_Reports_NewSites_Table_Header_Buid',
    render: (row, width) => <GridCell width={width}>{row.buid}</GridCell>,
    columnKey: 'buid',
    initialWidth: 150
  },
  {
    columnTitle: 'UI_Reports_NewSites_Table_Header_AccessTag',
    render: (row, width) => <GridCell width={width}>{row.accessTag}</GridCell>,
    columnKey: 'accessTag',
    initialWidth: 150
  },
  {
    columnTitle: 'UI_Reports_NewSites_Table_Header_Created',
    render: (row, width) => <GridCell width={width}>{formatDate(row.created || undefined, true, '.')}</GridCell>,
    columnKey: 'created',
    initialWidth: 200
  }
];

const assignedControllersColumns: IReactGridColumn<
  NewSitesReportQuery_reports_newSites_data_assignedControllers
>[] = [
  {
    columnTitle: 'UI_Reports_NewSites_Table_Header_Alias',
    render: (row, width) => (
      <GridCell
        width={width}
        className="link clickable"
        columnLink={`/controllermanager/${row.controllerId}`}
      >
        {row.alias?.trim()}
      </GridCell>
    ),
    columnKey: 'alias',
    initialWidth: 300
  },
  {
    columnTitle: 'UI_Reports_NewSites_Table_Header_ControllerType',
    render: (row, width) => <GridCell width={width}>{row.controllerType}</GridCell>,
    columnKey: 'controllerType',
    initialWidth: 200
  },
  {
    columnTitle: 'UI_Reports_NewSites_Table_Header_Serial',
    render: (row, width) => <GridCell width={width}>{row.serial}</GridCell>,
    columnKey: 'serial',
    initialWidth: 200
  },
  {
    columnTitle: 'UI_Reports_NewSites_Table_Header_AlternateSerial',
    render: (row, width) => <GridCell width={width}>{row.alternateSerial}</GridCell>,
    columnKey: 'alternateSerial',
    initialWidth: 200
  },
  {
    columnTitle: 'UI_Reports_NewSites_Table_Header_SiteAlias',
    render: (row, width) => (
      <GridCell
        width={width}
        className="link clickable"
        columnLink={`/sitedetails/${row.siteId}`}
      >
        {row.siteAlias?.trim()}
      </GridCell>
    ),
    columnKey: 'siteAlias',
    initialWidth: 300
  },
  {
    columnTitle: 'UI_Reports_NewSites_Table_Header_Buid',
    render: (row, width) => <GridCell width={width}>{row.buid}</GridCell>,
    columnKey: 'buid',
    initialWidth: 150
  },
  {
    columnTitle: 'UI_Reports_NewSites_Table_Header_AccessTag',
    render: (row, width) => <GridCell width={width}>{row.accessTag}</GridCell>,
    columnKey: 'accessTag',
    initialWidth: 150
  },
  {
    columnTitle: 'UI_Reports_NewSites_Table_Header_Created',
    render: (row, width) => <GridCell width={width}>{formatDate(row.created || undefined, true, '.')}</GridCell>,
    columnKey: 'created',
    initialWidth: 200
  }
];

const unassignedControllersColumns: IReactGridColumn<
  NewSitesReportQuery_reports_newSites_data_unassignedControllers
>[] = [
  {
    columnTitle: 'UI_Reports_NewSites_Table_Header_Alias',
    render: (row, width) => (
      <GridCell
        width={width}
        className="link clickable"
        columnLink={`/controllermanager/${row.controllerId}`}
      >
        {row.alias?.trim()}
      </GridCell>
    ),
    columnKey: 'alias',
    initialWidth: 300
  },
  {
    columnTitle: 'UI_Reports_NewSites_Table_Header_ControllerType',
    render: (row, width) => <GridCell width={width}>{row.controllerType}</GridCell>,
    columnKey: 'controllerType',
    initialWidth: 200
  },
  {
    columnTitle: 'UI_Reports_NewSites_Table_Header_Serial',
    render: (row, width) => <GridCell width={width}>{row.serial}</GridCell>,
    columnKey: 'serial',
    initialWidth: 200
  },
  {
    columnTitle: 'UI_Reports_NewSites_Table_Header_AlternateSerial',
    render: (row, width) => <GridCell width={width}>{row.alternateSerial}</GridCell>,
    columnKey: 'alternateSerial',
    initialWidth: 200
  },
  {
    columnTitle: 'UI_Reports_NewSites_Table_Header_Created',
    render: (row, width) => <GridCell width={width}>{formatDate(row.created || undefined, true, '.')}</GridCell>,
    columnKey: 'created',
    initialWidth: 200
  }
];

const parkedSitesColumns: IReactGridColumn<
  NewSitesReportQuery_reports_newSites_data_parkedSites
>[] = [
  {
    columnTitle: 'UI_Reports_NewSites_Table_Header_SiteAlias',
    render: (row, width) => (
      <GridCell
        width={width}
        className="link clickable"
        columnLink={`/sitedetails/${row.siteId}`}
      >
        {row.alias?.trim()}
      </GridCell>
    ),
    columnKey: 'alias',
    initialWidth: 300
  },
  {
    columnTitle: 'UI_Reports_NewSites_Table_Header_Buid',
    render: (row, width) => <GridCell width={width}>{row.buid}</GridCell>,
    columnKey: 'buid',
    initialWidth: 150
  },
  {
    columnTitle: 'UI_Reports_NewSites_Table_Header_AccessTag',
    render: (row, width) => <GridCell width={width}>{row.accessTag}</GridCell>,
    columnKey: 'accessTag',
    initialWidth: 150
  },
  {
    columnTitle: 'UI_Reports_NewSites_Table_Header_ParkedSince',
    render: (row, width) => <GridCell width={width}>{formatDate(row.parkedSince || undefined, true, '.')}</GridCell>,
    columnKey: 'parkedSince',
    initialWidth: 200
  },
  {
    columnTitle: 'UI_Reports_NewSites_Table_Header_Created',
    render: (row, width) => <GridCell width={width}>{formatDate(row.created || undefined, true, '.')}</GridCell>,
    columnKey: 'created',
    initialWidth: 200
  }
];

interface INewSitesReportGridProps {
  variables?: NewSitesReportQueryQueryVariables;
  didChangeFilters: boolean;
}

const download = async (reportFilter: ScheduledReportFilter) => {
  const variables: NewSitesReportDownloadQueryVariables = {
    input: reportFilter
  };  

  try {
    const { promise } = runSingleQuery<NewSitesReportDownloadQuery, NewSitesReportDownloadQueryVariables>(NewSitesReportDownloadDocument, variables);
    const reponse = await promise;
    
    await downloadFileFromUrl(
      reponse.reports.newSites.blobUrl,
      'NewSitesReport.xlsx');
  } catch (error) {
    throw new Error('Could not download report');
  }
};


// New Sites Grid
const NewSitesReportGrid: FC<INewSitesReportGridProps> = ({
  variables
}) => {
  const [activeGrid, setActiveGrid] = useState(NewSitesReportGridType.NewSites);

  const newSitesData = useGridData(
    NewSitesReportQueryDocument,
    variables,
    t => t.reports.newSites.data.newSites.length || 0,
    t => t.reports.newSites.data.newSites
  );

  const [newSitesSortValues, newSitesSetSortValues] = useState<NewSitesSortValues>({
    sortColumnKey: "alias",
    sortDirection: SortDirection.Asc
  });

  const newSitesOrderedData = {
    ...newSitesData,
    data: newSitesData.data ? makeSortedData(newSitesData.data, newSitesSortValues.sortDirection, newSitesSortValues.sortColumnKey) : undefined
  };

  // Assigned Controllers Grid
  const assignedControllersData = useGridData(
    NewSitesReportQueryDocument,
    variables,
    t => t.reports.newSites.data.assignedControllers.length || 0,
    t => t.reports.newSites.data.assignedControllers
  );

  const [assignedControllersSortValues, assignedControllersSetSortValues] = useState<AssignedControllersSortValues>({
    sortColumnKey: "alias",
    sortDirection: SortDirection.Asc
  });

  const assignedControllersOrderedData = {
    ...assignedControllersData,
    data: assignedControllersData.data ? makeSortedData(assignedControllersData.data, assignedControllersSortValues.sortDirection, assignedControllersSortValues.sortColumnKey) : undefined
  };

  // Unassigned Controllers Grid
  const unassignedControllersData = useGridData(
    NewSitesReportQueryDocument,
    variables,
    t => t.reports.newSites.data.unassignedControllers.length || 0,
    t => t.reports.newSites.data.unassignedControllers
  );

  const [unassignedControllersSortValues, unassignedControllersSetSortValues] = useState<UnassignedControllersSortValues>({
    sortColumnKey: "alias",
    sortDirection: SortDirection.Asc
  });

  const unassignedControllersOrderedData = {
    ...unassignedControllersData,
    data: unassignedControllersData.data ? makeSortedData(unassignedControllersData.data, unassignedControllersSortValues.sortDirection, unassignedControllersSortValues.sortColumnKey) : undefined
  };

  // Parked Sites Grid
  const parkedSitesData = useGridData(
    NewSitesReportQueryDocument,
    variables,
    t => t.reports.newSites.data.parkedSites.length || 0,
    t => t.reports.newSites.data.parkedSites
  );

  const [parkedSitesSortValues, parkedSitesSetSortValues] = useState<ParkedSitesSortValues>({
    sortColumnKey: "alias",
    sortDirection: SortDirection.Asc
  });

  const parkedSitesOrderedData = {
    ...parkedSitesData,
    data: parkedSitesData.data ? makeSortedData(parkedSitesData.data, parkedSitesSortValues.sortDirection, parkedSitesSortValues.sortColumnKey) : undefined
  };

  const [t] = useCaseInsensitiveTranslation();

  return (
    <Card className="flex flex_1 mar_tm mar_rm mar_bm">
      <CardHeader
        action={
          <Button
            variant="secondary"
            className="download-reports"
            disabled={!variables}
            onClick={() => variables && download(variables.input)}
            icon="fa-download"
          >
            {t('ui_common_download')}
          </Button>
        }
      >
      New sites & devices
      </CardHeader>

      <Tabs
        sx={{
          '& button.Mui-selected': {
            backgroundColor: '#fafafa'
          },
          '& button:hover': {
            backgroundColor: '#fafafa'
          },
          '& button': { transition: 'background-color 0.5s ease;' },
          marginBottom: "1rem"
        }}
        variant={'fullWidth'}
        value={activeGrid}
        onChange={(_, newValue) => {
          setActiveGrid(newValue);
        }}
      >
        <StyledTab
          label={"New sites (" + (newSitesData?.data?.length || 0) + ")"}
          value={NewSitesReportGridType.NewSites}
        />
        <StyledTab
          label={"Assigned controllers (" + (assignedControllersData?.data?.length || 0) + ")"}
          value={NewSitesReportGridType.AssignedControllers}
        />
        <StyledTab
          label={"Unasssigned controllers (" + (unassignedControllersData?.data?.length || 0) + ")"}
          value={NewSitesReportGridType.UnassignedControllers}
        />
        <StyledTab
          label={"Parked sites (" + (parkedSitesData?.data?.length || 0) + ")"}
          value={NewSitesReportGridType.ParkedSites}
        />
      </Tabs>
      
      { (activeGrid == NewSitesReportGridType.NewSites) && (
        <Grid
          name="new_sites_grid"
          items={newSitesOrderedData}
          loading={newSitesData.isRevalidating && !newSitesData.data}
          columns={newSitesColumns}
          sortedColumnKey={newSitesSortValues.sortColumnKey}
          sortedDirection={newSitesSortValues?.sortDirection}
          sortChanged={(sortByColumn, sortDirection) =>
            newSitesSetSortValues({
              sortColumnKey: sortByColumn,
              sortDirection: sortDirection
            })
          }
        />
      )}

      { (activeGrid == NewSitesReportGridType.AssignedControllers) && (
        <Grid
          name="assigned_controllers_grid"
          items={assignedControllersOrderedData}
          loading={assignedControllersData.isRevalidating && !assignedControllersData.data}
          columns={assignedControllersColumns}
          sortedColumnKey={assignedControllersSortValues.sortColumnKey}
          sortedDirection={assignedControllersSortValues?.sortDirection}
          sortChanged={(sortByColumn, sortDirection) =>
            assignedControllersSetSortValues({
              sortColumnKey: sortByColumn,
              sortDirection: sortDirection
            })
          }
        />
      )}

      { (activeGrid == NewSitesReportGridType.UnassignedControllers) && (
        <Grid
          name="unassigned_controllers_grid"
          items={unassignedControllersOrderedData}
          loading={unassignedControllersData.isRevalidating && !unassignedControllersData.data}
          columns={unassignedControllersColumns}
          sortedColumnKey={unassignedControllersSortValues.sortColumnKey}
          sortedDirection={unassignedControllersSortValues?.sortDirection}
          sortChanged={(sortByColumn, sortDirection) =>
            unassignedControllersSetSortValues({
              sortColumnKey: sortByColumn,
              sortDirection: sortDirection
            })
          }
        />
      )}

      
      { (activeGrid == NewSitesReportGridType.ParkedSites) && (
        <Grid
          name="unassigned_controllers_grid"
          items={parkedSitesOrderedData}
          loading={parkedSitesData.isRevalidating && !parkedSitesData.data}
          columns={parkedSitesColumns}
          sortedColumnKey={parkedSitesSortValues.sortColumnKey}
          sortedDirection={parkedSitesSortValues?.sortDirection}
          sortChanged={(sortByColumn, sortDirection) =>
            parkedSitesSetSortValues({
              sortColumnKey: sortByColumn,
              sortDirection: sortDirection
            })
          }
        />
      )}
    </Card>
  );
};

export default NewSitesReportGrid;
