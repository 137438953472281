import { Reports, NewSitesReportQueryQueryVariables } from '$typings/graphql-codegen';
import React, { FC, useState } from 'react';
import ScheduledReportFilter from '../modules/schedule-report-filters/scheduled-report-filter.react';
import NewSitesReportGrid from './modules/new-sites-report-grid/new-sites-report-grid';

export interface INewSitesReportReportProps {
  scheduledReportId?: string;
}

const NewSitesReport: FC<INewSitesReportReportProps> = ({
  scheduledReportId
}) => {
  const [variables, setVariables] = useState<
  NewSitesReportQueryQueryVariables
  >();
  const [didChangeFilters, setDidChangeFilters] = useState<boolean>(false);

  return (
    <div className="new-sites-report flex ai-stretch fill-height">
      <ScheduledReportFilter
        scheduledReportId={scheduledReportId}
        allowRunningUnfiltered
        report={Reports.NewSites}
        filtersChanged={input => { 
          if (!didChangeFilters) {
            setDidChangeFilters(true);
          }
          setVariables({ input });
        }}     
      />
      <NewSitesReportGrid variables={variables} didChangeFilters={didChangeFilters}/>
    </div>
  );
};

export default NewSitesReport;
